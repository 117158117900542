'use client';

import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { createClient } from '../../utils/supabase/client';
import GoogleIcon from '../components/GoogleIcon';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const supabase = createClient();
  const toast = useToast();

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session) {
        setIsLoading(true);

        const response = await fetch('/api/saveSessionData', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: session.user.id,
            session_data: session,
            user_data: session.user,
          }),
        });
        const data = await response.json();
        if (!response.ok) {
          console.error('Error saving session data:', data.error);
        }
        // Navigate to the next page regardless of the response status
        router.push('/leads');
      }
      setIsLoading(false);
    };

    checkSession();
  }, [router, supabase]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (data.session) {
      return router.push('/leads');
    }

    if (error) {
      setIsLoading(false);
      return toast({
        title: 'Login failed',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/callback`,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
          scopes:
            'https://www.googleapis.com/auth/calendar.readonly, https://www.googleapis.com/auth/calendar.events',
        },
      },
    });

    if (error) {
      toast({
        title: 'OAuth login error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <VStack
      spacing={4}
      padding={8}
      bg="white"
      boxShadow="none"
      overflow="hidden"
      align="stretch"
    >
      <Box display={{ md: 'none', base: 'flex' }} alignSelf="center" mb={4}>
        <Link href="/">
          <Image
            src="/lineage-crm-stacked-dark.png"
            alt="Lineage Logo"
            width={50}
            height={50}
          />
        </Link>
      </Box>

      <Box marginBottom={6}>
        <Text fontSize="3xl" fontWeight="bold" color="gray.900">
          Welcome back
        </Text>
        <Text fontSize="lg" color="gray.600">
          Please provide your email and password to sign in.
        </Text>
      </Box>

      <Box as="form" onSubmit={handleSignIn} w="full">
        <FormControl id="email" mb="4">
          <FormLabel color="gray.900">Email</FormLabel>
          <Input
            type="email"
            placeholder="Sign in with your email address"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="lg"
            borderWidth={1}
            borderColor="gray.300"
            _focus={{
              ring: 'none',
              outline: 'none',
              borderColor: 'green.600',
              borderWidth: 1,
            }}
            _placeholder={{
              fontSize: 16,
            }}
          />
        </FormControl>

        <FormControl id="password" mb="4">
          <FormLabel color="gray.900">Password</FormLabel>
          <Input
            type="password"
            placeholder="Provide your password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            size="lg"
            borderWidth={1}
            borderColor="gray.300"
            _focus={{
              ring: 'none',
              outline: 'none',
              borderColor: 'green.600',
              borderWidth: 1,
            }}
            _placeholder={{
              fontSize: 16,
            }}
          />
        </FormControl>

        <Button
          type="submit"
          colorScheme="green"
          color="white"
          width="full"
          size="lg"
          marginTop={6}
          isLoading={isLoading}
        >
          Sign in
        </Button>
      </Box>

      <Link href="/reset-password" color="gray.600">
        <Text textAlign="right">Forgot your password?</Text>
      </Link>

      <Box position="relative" padding="10">
        <Divider />
        <AbsoluteCenter bg="white" px="4">
          <Text color="gray.600" fontSize="large">
            Or sign in with
          </Text>
        </AbsoluteCenter>
      </Box>

      <VStack spacing={8} align="stretch">
        <Button
          isLoading={isLoading}
          onClick={handleGoogleSignIn}
          variant="outline"
          borderRadius="8px"
          borderColor="gray.300"
          size="lg"
          gap="2"
          w="full"
        >
          <GoogleIcon />
          <span>Google</span>
        </Button>

        <Text alignSelf="center" fontSize="sm" color="gray.600">
          Don&apos;t have an account?
          <Link href="/login/createAccount" color="green.600" ml="1">
            Sign up
          </Link>
        </Text>
      </VStack>

      <Text
        fontSize="xs"
        color="gray.600"
        alignSelf="center"
        mt={4}
        textAlign="center"
      >
        <i>
          By signing in you agree to the Lineage CRM
          <Link href="/terms" color="blue.500" ml="1">
            terms of service{' '}
          </Link>
          and
          <Link href="/privacy" color="blue.500" ml="1">
            privacy policy
          </Link>
          .
        </i>
      </Text>
    </VStack>
  );
};

export default Login;
