import React from 'react';

const GoogleIcon = () => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.76005 11.6192C4.76005 10.8645 4.8854 10.1409 5.10912 9.46228L1.19319 6.47192C0.429991 8.0215 0 9.76755 0 11.6192C0 13.4693 0.429463 15.2143 1.1916 16.7628L5.10542 13.7667C4.88381 13.0912 4.76005 12.3702 4.76005 11.6192Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6339 4.7533C13.2735 4.7533 14.7544 5.33426 15.918 6.28492L19.3029 2.9048C17.2402 1.1091 14.5957 0 11.6339 0C7.03569 0 3.08379 2.62963 1.19141 6.47188L5.10734 9.46224C6.00964 6.72328 8.58165 4.7533 11.6339 4.7533Z"
        fill="#EB4335"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6339 18.4853C8.58165 18.4853 6.00964 16.5153 5.10734 13.7764L1.19141 16.7662C3.08379 20.609 7.03569 23.2386 11.6339 23.2386C14.472 23.2386 17.1815 22.2309 19.2151 20.3428L15.498 17.4692C14.4492 18.1299 13.1286 18.4853 11.6339 18.4853Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.7396 11.6194C22.7396 10.9328 22.6338 10.1934 22.4752 9.50684H11.6328V13.9961H17.8738C17.5617 15.5266 16.7123 16.7033 15.4969 17.4691L19.214 20.3428C21.3502 18.3601 22.7396 15.4067 22.7396 11.6194Z"
        fill="#4285F4"
      />
    </svg>
  );
};

export default GoogleIcon;
